<template>
  <Toast />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Inversi&oacute;n en Compras</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>CAPITAL INVERTIDO EN MI EMPRESA</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <span><strong>SUCURSAL: </strong></span>
            <Dropdown
              v-model="sucursalSeleccionada"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-8">
            <span><strong>NOMBRE PRODUCTO: </strong></span>
            <AutoComplete
              :dropdown="true"
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }}</strong
                  ><span
                    ><strong> ** </strong
                    ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                  >
                  {{ slotProps.item.fabrica_nombre }}
                  <!--  <span v-if="sucursales.length > 2">
                    {{ slotProps.item.stock[0].nombre_sucursal }}
                  </span> -->
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>FECHA MOVIMIENTO DESDE: </strong></span>
            <Calendar
              v-model="fecha_inicio"
              dateFormat="dd/mm/yy"
              :showIcon="true"
            />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>FECHA MOVIMIENTO HASTA: </strong></span>
            <Calendar
              v-model="fecha_fin"
              dateFormat="dd/mm/yy"
              :showIcon="true"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="acciones"><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-info"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="card">
        <p>
          TOTAL INVERSI&Oacute;N:
          <strong
            >{{ convertirNumeroGermanicFormat(sumaTotalInversion) }} Bs.</strong
          >
        </p>
      </div>
      <div class="mt-4">
        <DataTable
          ref="dtcompras"
          key="id"
          :value="kardex_almacens"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm white-space-nowrap"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
          :rows="50"
          :paginator="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Inversión en Compras"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Inversion DescargarPDF' in auth.user.permissions"
                  label="Imprimir PDF"
                  icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'"
                  :loading="generandoPDF"
                  :disabled="generandoPDF"
                  @click="generarPDF"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <Button
                  v-if="'R_Inversion DescargarEXCEL' in auth.user.permissions"
                  label="Exportar EXCEL"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success"
                  v-tooltip.top="'Imprimir Reporte Excel'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportInversionExcel"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column
            field="producto_id"
            header="COD_PROD"
            :sortable="true"
            class="text-right"
          >
          </Column>
          <Column
            field="sucursal_nombre"
            header="SUCURSAL"
            v-if="sucursales.length > 2"
          >
          </Column>
          <Column field="fecha" header="FECHA MOV" :sortable="true"> </Column>
          <Column field="producto_nombre" header="DESCRIPCIÓN/PRODUCTOS">
          </Column>
          <Column field="saldo_cantidad" header="CANTIDAD" class="text-right">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.saldo_cantidad) }}
            </template>
          </Column>
          <Column field="cpp" header="PRECIO" class="text-right">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.cpp) }}
            </template>
          </Column>
          <Column
            field="saldo_total"
            header="TOTAL INVERSIÓN"
            class="text-right"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.saldo_total) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import OrdenCompraService from "@/service/OrdenCompraService";
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";

export default {
  data() {
    return {
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSeleccionada: 0,
      proveedors: [{ id: 0, nombre_completo: "TODOS" }],
      proveedorSeleccionado: 0,
      enviando: false,
      fecha_inicio: null,
      fecha_fin: null,
      kardex_almacens: [],
      generandoPDF: false,
      exportando: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      buscar: {
        global: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  ordenCompraService: null,
  sucursalService: null,
  UserService: null,
  productService: null,
  auth: null,
  created() {
    this.ordenCompraService = new OrdenCompraService();
    this.sucursalService = new SucursalService();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
  },
  computed: {
    sumaTotalInversion() {
      return sum(
        this.kardex_almacens.map((item) => {
          return parseFloat(item.saldo_total);
        })
      );
    },
  },
  methods: {
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSeleccionada,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    generarPDF() {
      if (this.kardex_almacens.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal_id: this.sucursalSeleccionada,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          producto_id: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ordenCompraService.imprimirRInversionPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    exportInversionExcel() {
      if (this.kardex_almacens.length > 0) {
        this.exportando = true;
        let datos = {
          sucursal_id: this.sucursalSeleccionada,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          producto_id: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ordenCompraService.exportarInversion(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se ha generado el archivo correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "No hay datos para exportar",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.kardex_almacens = [];
      this.enviando = true;
      this.cargarReporteInversion();
    },
    cargarReporteInversion() {
      let datos = {
        sucursal_id:
          this.sucursalSeleccionada == 0 ? null : this.sucursalSeleccionada,
        producto_id: this.productoSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
      };
      this.ordenCompraService.inversionNegocio(datos).then((response) => {
        this.kardex_almacens = response.kardex_almacens;
        this.enviando = false;
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
